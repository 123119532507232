"use client";

import { cn } from "@/lib/utils";
import { CookieIcon } from "lucide-react";
import Link from "next/link";
import { useEffect, useState } from "react";
import { Button } from "./ui/button";

export default function CookieConsent() {
  const [isOpen, setIsOpen] = useState(false);

  const onAcceptCallback = () => {};
  const onDeclineCallback = () => {};

  const accept = () => {
    setIsOpen(false);
    document.cookie =
      "cookieConsent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    onAcceptCallback();
  };

  const decline = () => {
    setIsOpen(false);
    onDeclineCallback();
  };

  useEffect(() => {
    try {
      setIsOpen(true);
      if (document.cookie.includes("cookieConsent=true")) {
        setIsOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  if (!isOpen) return null;

  return (
    <div
      className={cn(
        "fixed z-[200] bottom-0 left-0 right-0 sm:left-4 sm:bottom-4 w-1/4 duration-700 m-4 shadow-md",
        !true
          ? "transition-[opacity,transform] translate-y-8 opacity-0"
          : "transition-[opacity,transform] translate-y-0 opacity-100",
        !isOpen && "hidden"
      )}
    >
      <div className="dark:bg-secondary bg-background rounded-md m-3 border border-border shadow-lg dark:shadow-none">
        <div className="grid gap-2">
          <div className="border-b border-border dark:border-background/20 h-14 flex items-center justify-between p-4">
            <h1 className="text-lg font-medium">We use cookies</h1>
            <CookieIcon className="h-[1.2rem] w-[1.2rem]" />
          </div>
          <div className="p-4 w-1/4">
            <p className="text-sm font-normal text-start">
              We use cookies to ensure you get the best experience on our
              website. <br />
              For more information on how we use cookies, please see our cookie
              policy.
              <br />
              <br />
              <span className="text-xs">
                By clicking Accept you agree to our use of cookies.
              </span>
              <Link
                prefetch={false}
                href="/privacy"
                className="text-xs underline mt-2 ml-2"
              >
                Learn more.
              </Link>
            </p>
          </div>
          <div className="flex gap-2 p-4 py-5 border-t border-border dark:bg-background/20">
            <Button onClick={accept} className="w-full text-white">
              Accept All
            </Button>
            <Button onClick={accept} variant="secondary" className="w-full">
              Accept only required
            </Button>
            <Button onClick={decline} className="w-full" variant="secondary">
              Decline
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
