"use client"

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import Image from 'next/image'
import { useRouter, useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'
import { FilteredToken } from './Dashboard'

function TokenSelector({ tokens }: ({ tokens: FilteredToken[] })) {
    const router = useRouter()
    const searchParams = useSearchParams()
    const [selectedToken, setSelectedToken] = useState<string>('')

    useEffect(() => {
        const token = searchParams.get('token')
        if (token) {
            setSelectedToken(token)
        }
    }, [searchParams])

    const handleChange = (value: string) => {
        setSelectedToken(value)
        const params = new URLSearchParams(searchParams.toString())
        params.set('token', value)
        router.push(`/?${params.toString()}`)
    }

    return (
        <Select value={selectedToken} onValueChange={handleChange}>
            <SelectTrigger className="p-2 border rounded max-w-[150px]">
                <SelectValue placeholder="Filter by Token" />
            </SelectTrigger>
            <SelectContent>
                {tokens.map(token => (
                    <SelectItem key={token.symbol} value={token.symbol!}>
                        <div className='flex text-sm space-x-4'>
                            {token.symbol} <Image className='ml-4' src={token.tokenImage || ""} height={20} width={20} alt="Token Icon" />
                        </div>
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    )
}

export default TokenSelector
