"use client";

import { useEffect, useState } from 'react';
import { formatEther } from 'viem';
import { useAccount, useBalance } from "wagmi";

function Balance() {
    const [isClientSideLoading, setClientSideLoading] = useState(true);

    const { address, isConnected } = useAccount();

    const { data: tokenBalance, isFetching: isTokenBalanceFetching } = useBalance({
        address,
        query: {
            enabled: isConnected,
        },
        token: process.env.NEXT_PUBLIC_PRODUCT_TOKEN_ADDRESS as `0x${string}`,
    });

    useEffect(() => {
        if (!isTokenBalanceFetching) {
            setClientSideLoading(false);
        }
    }, [isTokenBalanceFetching]);

    if (!address || !isConnected || isClientSideLoading) {
        return (
            <div className="w-full  font-bold text-center block mt-2">
                <p className="capitalize bg-secondary p-2">Offline</p>
            </div>
        );
    }

    return (
        <div className="w-full font-bold text-center block">
            {<p className="capitalize bg-secondary p-2">{parseFloat(formatEther(BigInt((tokenBalance?.value || '0') as string))).toFixed(0)} MCADE</p>}
        </div>
    );
}

export default Balance;
