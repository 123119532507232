import { Tournament } from '@/lib/schema';
import { userIdToBase64 } from '@/lib/utils';
import { CheckCircleIcon, ClockIcon, GamepadIcon, TicketIcon, Trophy, UserIcon } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { CardOptions } from './GameCardOptions';
import JoinTournamentButton from './JoinTournamentButton';
import PrizePool from './PrizePool';
import { TournamentTooltip } from './Tooltip';
import { Button } from './ui/button';

interface TournamentListProps {
    tournaments: Tournament[];
    tournamentId: number;
}

export default function TournamentList({ tournaments }: TournamentListProps) {
    const hasCompleted = (status: string, endTime: string) => {
        return status === 'COMPLETE' || +new Date(endTime) / 1000 < +new Date() / 1000 ? true : false;
    }

    const formatDate = (endTime: string) => {
        const _endTime = new Date(endTime);
        const date = _endTime.toLocaleDateString('en-US');
        const time = _endTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
        return `${date} at ${time}`;
    }

    const activeTournaments = tournaments.filter(tournament => tournament.status == 'ACTIVE')

    return (
        <div className="h-full flex flex-col">
            <div className="overflow-y-auto pr-4 custom-scrollbar" style={{ maxHeight: 'calc(3 * 150px)' }}>
                <div className="space-y-3">

                    {activeTournaments.map((tournament) => (
                        <Link key={tournament.tournamentId} href={`/tournament/${tournament.tournamentId}`} prefetch={false}>
                            <div className="flex flex-col sm:flex-row items-start sm:items-center bg-card p-4 border rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 hover:opacity-80 mb-3 cursor-pointer">
                                <div className="flex items-start justify-between w-full sm:w-2/3">
                                    <div className="flex items-center space-x-4">
                                        <Image
                                            src={tournament.coverImageUrl || ""}
                                            alt={tournament.gameName || "Game Image"}
                                            width={50}
                                            height={50}
                                            className="rounded-full"
                                        />
                                        <div className="space-y-1">
                                            <h3 className="text-lg font-bold">Tournament {tournament.tournamentId}</h3>
                                            <p className="text-sm text-gray-300">{tournament.gameName}</p>
                                            <div className="flex items-center space-x-2 text-sm text-gray-300">
                                                <TicketIcon className="w-4 h-4" />
                                                <span className="font-bold">{tournament.paymentTokenSymbol}</span>
                                            </div>
                                            {tournament.createdByUsername ? (
                                                <Link prefetch={false} href={`/profile/${userIdToBase64(tournament.createdBy || "")}`}>
                                                    <div className="flex items-center space-x-1 text-sm text-gray-300 cursor-pointer hover:text-gray-100">
                                                        <UserIcon className="w-4 h-4" />
                                                        <span className="font-medium">{tournament.createdByUsername}</span>
                                                    </div>
                                                </Link>
                                            ) : (
                                                <div className="flex items-center space-x-1 text-sm text-gray-300">
                                                    <UserIcon className="w-4 h-4" />
                                                    <span className="font-medium">Metacade</span>
                                                    <CheckCircleIcon className="w-3 h-3 text-green-500" />
                                                </div>
                                            )}
                                            <div className="flex items-center space-x-1 text-sm text-gray-300">
                                                <ClockIcon className="w-4 h-4" />
                                                <span className="font-medium">{formatDate(tournament.endTime)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col space-y-2 sm:hidden">
                                        <Link href={`/game/${tournament.gameId}`} className="w-auto" prefetch={false}>
                                            <Button variant="secondary" className="flex items-center justify-center space-x-2 rounded-xl">
                                                <GamepadIcon className="h-4 w-4" />
                                                <span>Free Play</span>
                                            </Button>
                                        </Link>
                                        <CardOptions tournament={tournament} />
                                        <JoinTournamentButton
                                            key={`${tournament.tournamentId}_${tournament.gameId}`}
                                            tournament={tournament}
                                            activeTournaments={activeTournaments}
                                            hasCompleted={tournament.status === 'COMPLETE' || +new Date(tournament.endTime) / 1000 < +new Date() / 1000 ? true : false}
                                        />
                                    </div>
                                </div>
                                <div className="hidden sm:flex flex-col space-y-2 sm:w-1/3 mt-4 sm:mt-0">
                                    <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                                        <Link href={`/game/${tournament.gameId}`} className="w-full sm:w-auto">
                                            <Button variant="secondary" className="flex items-center justify-center space-x-2 rounded-xl w-full">
                                                <GamepadIcon className="h-4 w-4" />
                                                <span>Free Play</span>
                                            </Button>
                                        </Link>
                                        <JoinTournamentButton
                                            key={`${tournament.tournamentId}_${tournament.gameId}`}
                                            tournament={tournament}
                                            activeTournaments={activeTournaments}
                                            hasCompleted={tournament.status === 'COMPLETE' || +new Date(tournament.endTime) / 1000 < +new Date() / 1000 ? true : false}
                                        />
                                        <CardOptions tournament={tournament} />
                                    </div>
                                    <div className="flex justify-center w-full">
                                        <PrizePool
                                            address={tournament.address}
                                            tournamentId={tournament.tournamentId}
                                            winnerSpread={tournament.winnerSpread}
                                            network={tournament.network}
                                            chainId={tournament.chainId}
                                            paymentTokenSymbol={tournament.paymentTokenSymbol}
                                            paymentTokenDecimals={tournament.paymentTokenDecimals}
                                        >
                                            <TournamentTooltip text={`The current amount of ${tournament.paymentTokenSymbol} in the prize pool`}>
                                                <Trophy className='w-6 h-5' style={{ color: 'var(--primary-color)' }} />
                                            </TournamentTooltip>
                                        </PrizePool>
                                    </div>
                                </div>
                                <div className="w-full sm:hidden flex justify-center mt-4">
                                    <PrizePool
                                        address={tournament.address}
                                        tournamentId={tournament.tournamentId}
                                        winnerSpread={tournament.winnerSpread}
                                        network={tournament.network}
                                        chainId={tournament.chainId}
                                        paymentTokenSymbol={tournament.paymentTokenSymbol}
                                        paymentTokenDecimals={tournament.paymentTokenDecimals}
                                    >
                                        <TournamentTooltip text={`The current amount of ${tournament.paymentTokenSymbol} in the prize pool`}>
                                            <Trophy className='w-6 h-5' style={{ color: 'var(--primary-color)' }} />
                                        </TournamentTooltip>
                                    </PrizePool>
                                </div>
                            </div>
                        </Link>
                    ))}

                </div>
            </div>
        </div>
    );
}