"use client"

import { Tournament } from "@/lib/schema";
import { OnchainKitProvider } from '@coinbase/onchainkit';
import { HelpCircle } from "lucide-react";
import { useAccount } from "wagmi";
import { base } from "wagmi/chains";
import BuyMetacadeButton from "./BuyMetacadeButton";
import Header from "./Header";
import RotatingWords from "./RotateWord";
import { TournamentTooltip } from "./Tooltip";
import WalletActions from "./WalletActions";

function GetStarted({ tournaments, network }: { tournaments: Tournament[], network: string }) {
    const { isConnected } = useAccount()

    // Only tournaments that have ended can be claimed from
    const claimableTournaments = tournaments.filter((tournament) => tournament.status === 'COMPLETE') || []
    const possibleSelectedClaimableTournament: Tournament | null = claimableTournaments.find((tournament) => tournament) || null
    // Only show the latest two possible tournaments that are ended sorted by end time
    const latestTwoTournamentsFilteredDownPerCatagory = []

    // firstly sort claimableTournaments by end time into a new var
    const endTimeSorted = [...claimableTournaments.sort((a, b) => +new Date(b.endTime) - +new Date(a.endTime))]
    const gameIds = new Set(claimableTournaments.map((tournament) => tournament.gameId))

    // Only push in tournaments that ended in the last 3 weeks based on current timestamp and tournament endtime
    const currentTime = +new Date() / 1000
    const tenDaysAgo = currentTime - 864000 // 10 days in seconds
    for (const gameId of gameIds) {
        const gameTournaments = endTimeSorted.filter((tournament) => tournament.gameId === gameId)
        const latestTwoTournaments = gameTournaments.filter((tournament) => +new Date(tournament.endTime) / 1000 > tenDaysAgo)
        latestTwoTournamentsFilteredDownPerCatagory.push(...latestTwoTournaments.slice(0, 6))
    }
    const tournamentsThatAreNotEnded = tournaments.filter((tournament) => +new Date(tournament.endTime) / 1000 > +new Date() / 1000 && tournament.status !== 'COMPLETE' && tournament.status !== 'PAUSED') || []
    const possibleSelectedSwapTournament: Tournament | null = tournamentsThatAreNotEnded.find((tournament) => tournament) || null

    return (
        <div className="card-border bg-card lg:col-start-7 col-start-1 col-span-full crt-texture block pb-3 mt-14">
            <Header heading="MANAGE CREDITS" showSection={false}>
                <TournamentTooltip text={`Credits are required to participate in tournaments, connect your wallet and click 'Get Credits' below to get started.`}>
                    <HelpCircle className='cursor-help' />
                </TournamentTooltip>
            </Header>
            <RotatingWords words={['Jump', 'Hit', 'Fly', "Run", "Slash"]} />
            <div className="flex flex-col items-center pb-8">
                <OnchainKitProvider apiKey={process.env.NEXT_PUBLIC_ONCHAINKIT_API_KEY} projectId={process.env.NEXT_PUBLIC_CDP_PROJECT_ID} chain={base as any}>
                    <BuyMetacadeButton />
                    {isConnected && <WalletActions network={network} showOnlyFirstTwoModals={false} claimableTournaments={latestTwoTournamentsFilteredDownPerCatagory} possibleSelectedClaimableTournament={possibleSelectedClaimableTournament} possibleSelectedSwapTournament={possibleSelectedSwapTournament} tournamentsThatAreNotEnded={tournamentsThatAreNotEnded} />}
                </OnchainKitProvider>
            </div>
        </div>
    )
}

export default GetStarted