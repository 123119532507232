"use client"

import { Score, Tournament } from "@/lib/schema";
import { userIdToBase64 } from "@/lib/utils";
import { HelpCircle } from "lucide-react";
import Link from "next/link";
import useSWR from "swr";
import { useAccount } from "wagmi";
import ClientCarousel from "./ClientCarousel";
import Header from "./Header";
import LeaderRow from "./LeaderRow";
import { TournamentTooltip } from "./Tooltip";

type LeaderBoardProps = {
    tournaments: Tournament[]
    isBlindLeaderboard: boolean
    tournamentId: number
    isActive: boolean
    gameId: number
    paymentTokenSymbol: string
    network: string
    leaderboardTopPlayers: Score[]
    winnerSpread: number
    paymentTokenDecimals: number
}

type PersonalScore = {
    data: Score
    index: number
}

function UserPositionCompact({ tournamentId, isBlindLeaderboard }: { tournamentId: number, isBlindLeaderboard: boolean }) {
    const { address } = useAccount()
    const fetcher = (url: string) => fetch(url).then((res) => res.json());

    const { data, isLoading }: { data: PersonalScore, isLoading: boolean } = useSWR(
        address ? `${process.env.NEXT_PUBLIC_API_ENDPOINT}/leaderboard?tournamentId=${tournamentId}&wallet=${address}` : null,
        fetcher
    )

    if (!address) return null;
    if (isLoading) return null

    return data ? (
        <div className="text-sm text-muted-foreground mb-2">
            Your position: <span className="font-semibold">{isBlindLeaderboard ? "???" : data.index}</span> |
            Score: <span className="font-semibold">{isBlindLeaderboard ? "???" : (data.data?.value || "0")}</span>
        </div>
    ) : null;
}

function LeaderBoard({ leaderboardTopPlayers, tournaments, tournamentId, gameId, paymentTokenDecimals, paymentTokenSymbol, network, winnerSpread, isBlindLeaderboard, isActive }: LeaderBoardProps) {
    const { isConnected } = useAccount()
    // Filter the tournaments to ensure we only show tournaments that ended in the last 3 weeks or are currently running
    const currentTime = +new Date() / 1000
    const tenDaysAgo = currentTime - 864000 // 10 days in seconds
    const filteredTournaments = tournaments.filter((tournament) => +new Date(tournament.endTime) / 1000 > tenDaysAgo || tournament.status === 'ACTIVE')

    return (
        <article className="card-border leaderboard bg-card p-4">
            <Header heading="LEADERBOARD Top 10" showSection={false}>
                <TournamentTooltip text={`If multiple players have the same score, the player who registered the score first will be placed ahead.`}>
                    <HelpCircle className='cursor-help' />
                </TournamentTooltip>
            </Header>

            <ClientCarousel
                tournaments={filteredTournaments}
                key={`carousel-${tournamentId}`}
                selectedTournamentId={tournamentId}
                gameId={gameId}
                network={network}
            />

            {isConnected && <UserPositionCompact tournamentId={tournamentId} isBlindLeaderboard={isBlindLeaderboard} />}

            <div className="grid grid-cols-12 gap-2 py-2 px-4 text-xs sm:text-sm text-muted-foreground">
                <div className="col-span-1">#</div>
                <div className="col-span-7 sm:col-span-5">Player</div>
                <div className="col-span-2 sm:col-span-3 text-right sm:text-left">Score</div>
                <div className="col-span-2 sm:col-span-3 text-right">Prize</div>
            </div>

            {/* Top 10 Players - Always Visible */}
            <div className="space-y-0.5 sm:space-y-1">
                {leaderboardTopPlayers.slice(0, 10).map((score, index) => {
                    const position = index + 1;
                    const prizePercentage = calculatePrizePercentage(position, winnerSpread, leaderboardTopPlayers.length);
                    return (
                        <Link prefetch={false} key={`link_${score.id}`} href={isBlindLeaderboard ? `/tournament/${tournamentId}` : `/profile/${userIdToBase64(score.userId)}`}>
                            <LeaderRow
                                {...score}
                                paymentTokenDecimals={paymentTokenDecimals}
                                position={position}
                                key={score.id}
                                isActive={isActive}
                                isBlindLeaderboard={isBlindLeaderboard}
                                paymentTokenSymbol={paymentTokenSymbol}
                                winnerSpread={winnerSpread}
                                prizePercentage={prizePercentage}
                            />
                        </Link>
                    );
                })}
            </div>

            {/* Next 10 Players - Scrollable */}
            {leaderboardTopPlayers.length > 10 && (
                <div className="space-y-0.5 sm:space-y-1 mt-2 max-h-[200px] overflow-y-auto">
                    {leaderboardTopPlayers.slice(10).map((score, index) => {
                        const position = index + 11;
                        const prizePercentage = calculatePrizePercentage(position, winnerSpread, leaderboardTopPlayers.length);
                        return (
                            <Link prefetch={false} key={`link_${score.id}`} href={isBlindLeaderboard ? `/tournament/${tournamentId}` : `/profile/${userIdToBase64(score.userId)}`}>
                                <LeaderRow
                                    {...score}
                                    paymentTokenDecimals={paymentTokenDecimals}
                                    position={position}
                                    isBlindLeaderboard={isBlindLeaderboard}
                                    key={score.id}
                                    isActive={isActive}
                                    paymentTokenSymbol={paymentTokenSymbol}
                                    winnerSpread={winnerSpread}
                                    prizePercentage={prizePercentage}
                                />
                            </Link>
                        );
                    })}
                </div>
            )}
        </article>
    )
}

function calculatePrizePercentage(position: number, winnerSpread: number, participantCount: number): number {
    if (position > winnerSpread || position > participantCount) return 0;

    // If there's only one participant, they get 100%
    if (participantCount === 1) return 100;

    const effectiveSpread = Math.min(winnerSpread, participantCount);
    const totalWeight = (effectiveSpread * (effectiveSpread + 1)) / 2;
    const weight = effectiveSpread - position + 1;
    const percentage = (weight / totalWeight) * 100;

    return Number(percentage.toFixed(2));
}

export default LeaderBoard;
