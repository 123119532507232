"use client"

import { Tournament } from '@/lib/schema';
import { useRouter } from 'next/navigation';
import React from 'react';
import { Carousel, CarouselApi, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from './ui/carousel';

function ClientCarousel({ tournaments, selectedTournamentId, gameId, network }: { tournaments: Tournament[], selectedTournamentId: number, gameId: number, network: string }) {
    const startingIndex = tournaments.map(e => e.tournamentId).indexOf(selectedTournamentId);

    const router = useRouter()
    const [api, setApi] = React.useState<CarouselApi>()

    React.useEffect(() => {
        if (!api) {
            return
        }

        api.on('select', async (e) => {
            const index = api.selectedScrollSnap()
            const current = tournaments[index].tournamentId
            router.push(`/tournament/${current}?network=${network}`, { scroll: false })
        })
    }, [api, tournaments, router, network])


    return (
        <Carousel className="py-4 slider-gradient w-full text-center" setApi={setApi} opts={{
            align: "center",
            loop: true,
            startIndex: startingIndex
        }}>
            <CarouselContent>
                {tournaments.map((tournament) => (<CarouselItem key={tournament.id}>
                    <h2 className="lg:text-2xl mix-w-96 text-card-foreground">Tournament {tournament.tournamentId} ({tournament.gameName})</h2></CarouselItem>))}
            </CarouselContent>
            <CarouselPrevious className="card-border rounded-none left-[1rem] bg-none" />
            <CarouselNext className="card-border rounded-none right-[1rem] bg-none" />
        </Carousel>
    )
}

export default ClientCarousel