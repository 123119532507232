"use client"

import useClerkSWR from "@/hooks/useClerkHook";
import { Tournament } from "@/lib/schema";
import { CreditData } from "@/lib/utils";
import { useUser } from "@clerk/nextjs";
import { sendGAEvent } from "@next/third-parties/google";
import { useMemo, useState } from "react";
import { useAccount } from "wagmi";
import { CREDITS_KEY } from "./CreditsBox";
import { Modal } from "./Modal";
import BuyCredits from "./modal/BuyCredits";
import { Button } from "./ui/button";

function JoinTournamentButton({ tournament, activeTournaments, hasCompleted }: { tournament: Tournament, activeTournaments: Tournament[], hasCompleted: boolean }) {
    const [loaded, setIsLoaded] = useState<boolean>(false)
    const { address, isConnected } = useAccount()
    const [buyCreditsOpen, setBuyCreditsOpen] = useState<boolean>(false)
    const { isSignedIn, user } = useUser()

    const { data: creditData, isLoading: creditDataLoading }: { data: CreditData, isLoading: boolean } = useClerkSWR(
        CREDITS_KEY(tournament.tournamentId)
    )

    const hasMinRequiredCredits = useMemo(() => {
        if (!creditData || !isSignedIn) return false
        return creditData.data?.balance >= creditData.data?.balanceMin && creditData.data?.balanceMin != -1
    }, [creditData, isSignedIn])

    const buttonLabel = useMemo(() => {
        setIsLoaded(true)
        if (hasCompleted) return 'Completed'
        if (!address && !isConnected) return 'Connect Wallet'
        if (!hasMinRequiredCredits) return 'Get Credits'
        return 'Join'
    }, [hasCompleted, hasMinRequiredCredits, address, isConnected, buyCreditsOpen])

    const buttonDisabled = useMemo(() => {
        if (hasCompleted) return true
        if (!address && !isConnected) return true
        if (!tournament) return true
        return false
    }, [hasCompleted, hasMinRequiredCredits, address, isConnected, tournament])

    if (creditDataLoading || !loaded || !tournament) return (
        <Button variant="secondary" disabled={true} className="text-white font-bold mb-4 py-2 px-4 mt-4 rounded-xl w-full">
            Loading..
        </Button>
    )

    const url = `${process.env.NEXT_PUBLIC_BASE_SITE_URL}/game/${tournament.gameId}?tid=${tournament.tournamentId}`

    return (
        <>
            {isConnected && !hasMinRequiredCredits ? (
                <Modal
                    isOpen={buyCreditsOpen}
                    triggerButton={<Button variant="secondary" disabled={
                        !isSignedIn || Boolean(isConnected && user?.unsafeMetadata.wallet && (user?.unsafeMetadata?.wallet as string).toLowerCase() != address?.toLowerCase())
                    } className="text-white font-bold mb-4 py-2 px-4 mt-4 rounded-xl w-full hover:border-2 hover:border-secondary-foreground hover:animate-pulse" onClick={() => setBuyCreditsOpen(true)}>Get Credits</Button>}
                    setOpen={setBuyCreditsOpen}
                    heading={"Get Credits"}
                    description={"Swap Tokens for Credits, Credits allow you to compete in the selected Tournament. Note, credits do not carry over between tournaments."}
                >
                    <BuyCredits setOpen={setBuyCreditsOpen} activeTournaments={activeTournaments} directBuy={true} selectedTournament={tournament} beneficary={false} shouldRedirectToGameOnSuccess={true} />
                </Modal>
            ) : (
                <Button
                    variant="secondary"
                    disabled={buttonDisabled}
                    onClick={() => {
                        if (!tournament.gameId) return
                        // Open the game in a new tab
                        sendGAEvent('event', 'play_tournament_button', { wallet: address, email: user?.primaryEmailAddress?.emailAddress, username: user?.username, tournamentId: tournament.tournamentId })

                        console.log(`Opening game in new tab: ${url}`)
                        window.open(url, '_blank', 'noreferrer')
                    }}
                    className="text-white font-bold mb-4 py-2 px-4 mt-4 rounded-xl w-full hover:border-2 hover:border-secondary-foreground hover:animate-pulse"
                >
                    {buttonLabel}
                </Button>
            )}
        </>
    )
}

export default JoinTournamentButton