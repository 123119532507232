"use client"

import { Tournament } from "@/lib/schema";
import { formatReadableDate, formatUpperCaseString } from "@/lib/utils";
import { Loader } from "lucide-react";
import { useEffect, useState } from 'react';

interface CountdownTime {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

function calculateTimeLeft(endTime: string): CountdownTime {
    const difference = +new Date(endTime) - +new Date();

    let timeLeft: CountdownTime = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }

    return timeLeft;
}

function TournamentCountDown({ gameId, gameName, tournamentId, network, endTime }: Tournament) {
    const [timeLeft, setTimeLeft] = useState<CountdownTime>(calculateTimeLeft(endTime));
    const [ready, setReady] = useState<boolean>(false);
    const hasEnded = Math.floor(+new Date(endTime) - +new Date()) < 0;

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft(endTime));
        }, 1000);
        setReady(true);
        return () => clearTimeout(timer);
    }, []);

    if (!ready) return <Loader className="animate-spin" />

    return (
        <div className="flex flex-col">
            <div className="min-h-[96px] flex items-end">
                <h3 className="ml-4 px-4 py-4 text-xl lg:text-2xl font-semibold tracking-tight text-white mb-2 text-left w-full">
                    <div className="flex flex-wrap">
                        <span className="mr-2">{gameName}</span>
                        <span className="mr-2">{tournamentId}</span>
                        <span className="mr-2">on {formatUpperCaseString(network)}</span>
                    </div>
                    <div>
                        <span>{hasEnded ? 'Ended at:' : 'Ends in:'}</span>
                    </div>
                </h3>
            </div>
            <div className="flex items-center justify-start mt-4">
                {hasEnded ? (
                    <div className="flex flex-col justify-center mx-4 items-center">
                        <h1 className="font-normal text-lg text-white">Ended at: {formatReadableDate(new Date(endTime).toISOString())}</h1>
                    </div>
                ) : (
                    <div className="flex flex-row mb-4">
                        <span className="flex flex-col justify-center mx-4 items-center card-border p-2 w-18">
                            <h3 className="font-bold text-white">{timeLeft.days}</h3>
                            <p className="text-white">Days</p>
                        </span>
                        <span className="flex flex-col justify-center mx-4 items-center w-18">
                            <h3 className="font-bold text-white">{timeLeft.hours}</h3>
                            <p className="text-white">Hours</p>
                        </span>
                        <span className="flex flex-col justify-center mx-4 items-center w-18">
                            <h3 className="font-bold text-white">{timeLeft.minutes}</h3>
                            <p className="text-white">Minutes</p>
                        </span>
                        <span className="flex flex-col justify-center items-center w-18">
                            <h3 className="font-bold text-white">{timeLeft.seconds}</h3>
                            <p className="text-white">Seconds</p>
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TournamentCountDown;
