import { Score } from "@/lib/schema";
import { cn, formatNumber, truncateWalletAddress } from "@/lib/utils";

import Image from "next/image";
import { TournamentTooltip } from "./Tooltip";
import { Skeleton } from "./ui/skeleton";

const getBorderColor = (position: number) => {
    if (position === 1) {
        return 'border-yellow-500';
    } else if (position === 2) {
        return 'border-gray-500';
    } else if (position === 3) {
        return 'border-yellow-200';
    } else {
        return 'border-transparent';
    }
}

const getSourceIcon = (source: string) => {
    if (source === "TELEGRAM") {
        return <Image className="absolute top-0 right-0 text-primary bg-white rounded-full p-0.5" width={16} height={16} src="/telegram.png" alt="Telegram Logo" />;
    } else {
        return <Image className="absolute top-0 right-0 text-primary bg-white rounded-full p-0.5" width={16} height={16} src="/monitor.png" alt="Monitor Logo" />;
    }
}


function LeaderRow({ value, wallet, source, username, imageUrl, position, prize, paymentTokenSymbol, paymentTokenDecimals, winnerSpread, isBlindLeaderboard, prizePercentage, isActive }: Score & { position: number, paymentTokenSymbol: string, paymentTokenDecimals: number, winnerSpread: number, isBlindLeaderboard: boolean, prizePercentage: number, isActive: boolean }) {
    const isWinningPosition = position <= winnerSpread;
    const getBackgroundColor = () => {
        if (!isWinningPosition) return "bg-card/20 hover:bg-card/30";

        return `bg-slate-50 bg-opacity-10 hover:bg-opacity-30`;
    };


    const rowClassName = cn(
        "grid grid-cols-12 gap-2 py-2 px-4 items-center cursor-pointer",
        getBackgroundColor(),
        { "border-b-2 border-yellow-500": position === winnerSpread }
    );

    const getPositionDisplay = (position: number) => {
        switch (position) {
            case 1:
                return <span className="text-yellow-500 font-bold">🥇</span>;
            case 2:
                return <span className="text-gray-400 font-bold">🥈</span>;
            case 3:
                return <span className="text-yellow-600 font-bold">🥉</span>;
            default:
                return `#${position}`;
        }
    };

    // Calculate if we should show blind data - only if isBlindLeaderboard is true AND tournament hasn't completed
    const showBlindData = isBlindLeaderboard && isActive;

    return (
        <div className={rowClassName}>
            <div className="col-span-1 text-card-foreground text-xs sm:text-sm">
                {showBlindData ? "?" : getPositionDisplay(position)}
            </div>
            <div className="col-span-7 sm:col-span-5 text-card-foreground">
                <div className="flex items-center space-x-2">
                    <div className="relative flex-shrink-0">
                        {showBlindData ? (
                            <Skeleton className="w-7 h-7 sm:w-9 sm:h-9 rounded-full" />
                        ) : (
                            <>
                                <Image
                                    src={imageUrl}
                                    alt="Avatar"
                                    width={28}
                                    height={28}
                                    priority={true}
                                    className={`rounded-full border-2 sm:w-9 sm:h-9 ${getBorderColor(position)}`}
                                />
                                {getSourceIcon(source || "")}
                            </>
                        )}
                    </div>
                    <p className="text-xs sm:text-sm truncate">
                        {showBlindData ? "Hidden" : (username || truncateWalletAddress(wallet))}
                    </p>
                </div>
            </div>
            <div className="col-span-2 sm:col-span-3 text-card-foreground text-xs sm:text-sm text-right sm:text-left">
                {showBlindData ? "Hidden" : value}
            </div>
            <div className="col-span-2 sm:col-span-3 text-card-foreground text-xs sm:text-sm text-right">
                {showBlindData ? (
                    isWinningPosition ? "Hidden" : "-"
                ) : prize ? (
                    formatNumber(prize.toString(), paymentTokenDecimals) + ' ' + paymentTokenSymbol
                ) : isWinningPosition ? (
                    <TournamentTooltip text={`Eligible for a prize when the tournament ends.`}>
                        <span title="Eligible for prize">💰 {prizePercentage}% of the pool</span>
                    </TournamentTooltip>
                ) : (
                    '-'
                )}
            </div>
        </div>
    );
}

export default LeaderRow;
