import { cn } from "@/lib/utils"
import * as React from "react"
import { Button, ButtonProps } from "./button"

export interface IconButtonProps extends ButtonProps {
    "aria-label": string
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
    ({ className, children, ...props }, ref) => {
        return (
            <Button
                ref={ref}
                className={cn("p-0 w-9 aspect-square", className)}
                {...props}
            >
                {children}
            </Button>
        )
    }
)
IconButton.displayName = "IconButton"

export { IconButton }
